import { useTranslation } from 'react-i18next'

import { useGetProductBarcodes } from '@/modules/admin-center/hooks/useGetProductBarcodes'
import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import Table from '@/modules/shared/components/table/Table'
import { BooleanItem } from '@/modules/shared/components/table/table-column-items/shared/BooleanItem'

interface BarcodeDetailsProps {
  productId: number
  isSearchProductUtility?: boolean
}

export default function BarcodeDetails({ productId, isSearchProductUtility = false }: BarcodeDetailsProps) {
  const { t } = useTranslation()

  const { barcodes } = useGetProductBarcodes({
    productIdProp: String(productId),
    skip: !isSearchProductUtility,
  })

  return (
    <Accordion
      title={t('general.barcodes', 'Barcodes')}
      buttonClassName="px-3 h-11 mt-2.5"
      titleClassName="font-bold"
      titleEndIcon={
        <div className="flex size-5 items-center justify-center rounded-md bg-gray-400 text-xs text-white">
          {barcodes.length}
        </div>
      }
    >
      <div className="mb-5">
        <p className="my-3 text-sm">
          {t(
            'productModal.barcodesDescription',
            'Your Organisation may add Barcodes to a Product so that the Barcode can be used to search for the Product while creating a Requisition, Receiving Goods or performing a Stocktake. '
          )}
        </p>
        <Table
          columns={[
            {
              title: t('general.barcode', 'Barcode'),
              key: 'barcodes',
              headerCellStyles: 'pl-4 py-3',
              cellStyles: 'pl-4 py-3',
              minWidth: 13,
              grow: true,
              header: {
                tooltip: t('product.barcode.barcodeTooltip', 'The Body of the Barcode.'),
              },
              render: ({ body }) => body,
            },
            {
              title: t('general.ownerID', 'Owner ID'),
              key: 'ownerID',
              headerCellStyles: 'py-3 text-center',
              cellStyles: 'py-3 text-center',
              minWidth: 6,
              maxWidth: 6,
              hidden: !isSearchProductUtility,
              header: {
                tooltip: t(
                  'product.barcode.ownerIDTooltip',
                  'The ID of the Organisation that owns and manages this Barcode.'
                ),
              },
              render: ({ organisation }) => organisation.id,
            },
            {
              title: t('general.isDefault', 'Is Default'),
              key: 'isDefault',
              headerCellStyles: 'py-3 text-center',
              cellStyles: 'py-3 text-center',
              minWidth: 6,
              maxWidth: 6,
              header: {
                tooltip: t(
                  'product.barcode.isDefaultTooltip',
                  'The default Barcode can be useful when printing Barcodes.'
                ),
              },
              render: ({ isDefault }) => <BooleanItem value={isDefault} />,
            },
          ]}
          dataSource={barcodes}
          keyExtractor={(record) => String(record.id)}
        />
        {barcodes.length === 0 && (
          <div className="border p-4 text-center text-gray-500">
            {t('product.barcodes.emptyBarcodes2', 'There are no Barcodes created for this product.')}
          </div>
        )}
      </div>
    </Accordion>
  )
}
