import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ComboboxClient } from '@/modules/shared/components/combobox/client/ComboboxClient'
import FormInput from '@/modules/shared/components/create-form/FormInput'
import { Tooltip } from '@/modules/shared/components/tooltip/Tooltip'
import { countries } from '@/modules/shared/constants'
import { QuestionMarkIcon } from '@/modules/shared/icons/QuestionMarkIcon'

export default function CheckoutDeliveryAddress() {
  const { t } = useTranslation()
  const { control, formState } = useFormContext()
  return (
    <section>
      <h2 className="text-sm font-semibold">{t('general.deliveryAddress', 'Delivery Address')}</h2>
      <p className="text-sm text-gray-500">
        {t(
          'publicShop.checkoutDetails.deliveryAddress',
          'Enter the delivery address that you would like the order to be delivered to'
        )}
      </p>
      <section className="mt-5 flex flex-col gap-y-5">
        <FormInput
          titleClassName="text-xs"
          title={t('general.addressLine1', 'Address Line 1')}
          placeholder={t('publicShop.checkout.delivery.addressLine1Placeholder', 'E.g. Unit 10')}
          tooltip={t(
            'publicShop.checkout.delivery.addressLine1Tooltip',
            'The floor or unit number of the premises, e.g Level 11, Unit 10.'
          )}
          register="deliveryAddressLine1"
          customErrorMsg={t('general.thisFieldIsRequired', 'This field is required.')}
        />
        <FormInput
          titleClassName="text-xs"
          title={t('general.addressLine2', 'Address Line 2')}
          placeholder={t('publicShop.checkout.delivery.addressLine2Placeholder', 'E.g. 86 King Street')}
          tooltip={t(
            'publicShop.checkout.delivery.addressLine2Tooltip',
            'The street address of the premises, e.g. 86 King Street.'
          )}
          register="deliveryAddressLine2"
        />
        <section>
          <h2 className="flex items-center text-xs font-semibold">
            {t('general.country', 'Country')}
            <Tooltip
              content={t(
                'publicShop.checkout.delivery.addressCountryTooltip',
                'The Country that is applicable to the Address.'
              )}
            >
              <QuestionMarkIcon className="ml-1 size-4" />
            </Tooltip>
          </h2>
          <Controller
            control={control}
            name="deliveryAddressCountry"
            render={({ field }) => {
              return (
                <ComboboxClient
                  className="mt-2"
                  placeholder={t('general.country', 'Country')}
                  items={countries}
                  keyFilter="name"
                  keyExtractor={(e) => e.code}
                  onSelected={(e) => field.onChange(e.code)}
                  hasError={!!formState.errors.deliveryAddressCountry}
                  errorMessage={t('general.thisFieldIsRequired', 'This field is required.')}
                />
              )
            }}
          />
        </section>
        <FormInput
          isHalfOnLargeScreen
          titleClassName="text-xs"
          title={t('publicShop.checkout.delivery.cityTownOrSuburb', 'City, Town or Suburb')}
          placeholder={t('publicShop.checkout.delivery.cityTownOrSuburbPlaceholder', 'E.g. Sydney')}
          tooltip={t(
            'publicShop.checkout.delivery.cityTownOrSuburbTooltip',
            'The City, Town or Suburb in which the Organisation is located.'
          )}
          register="deliveryAddressCity"
          customErrorMsg={t('general.thisFieldIsRequired', 'This field is required.')}
        />
        <FormInput
          isHalfOnLargeScreen
          titleClassName="text-xs"
          title={t('publicShop.checkout.delivery.stateRegionOrProvince', 'State, Region or Province')}
          placeholder={t('publicShop.checkout.delivery.stateRegionOrProvincePlaceholder', 'E.g. New South Wales')}
          tooltip={t(
            'publicShop.checkout.delivery.stateRegionOrProvinceTooltip',
            'The State, Region or Province in which the Organisation is located.'
          )}
          register="deliveryAddressStateProvince"
          customErrorMsg={t('general.thisFieldIsRequired', 'This field is required.')}
        />
        <FormInput
          isHalfOnLargeScreen
          titleClassName="text-xs"
          title={t('general.postalCode', 'Postal Code')}
          placeholder={t('general.postalCode', 'Postal Code')}
          tooltip={t(
            'publicShop.checkout.delivery.postalCodeTooltip',
            'The Zip or Postal Code that is applicable to the Address.'
          )}
          register="deliveryAddressPostalCode"
          customErrorMsg={t('general.thisFieldIsRequired', 'This field is required.')}
        />
      </section>
    </section>
  )
}
