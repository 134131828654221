import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PurchasePlusPublicBaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './public_graphql'

export const CreatePaymentDocument = gql`
  mutation CreatePayment($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      paymentUrl
      errors {
        ...PurchasePlusPublicBaseError
      }
    }
  }
  ${PurchasePlusPublicBaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.CreatePaymentMutation, Types.CreatePaymentMutationVariables>
