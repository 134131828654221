import { LOCAL_REQUISITION_KEY } from '@/modules/public-shop/hooks/useCreateLocalRequisition'
import { LocalRequisition } from '@/modules/public-shop/types/requisitionLine'
import useShopId from '@/modules/shared/hooks/params/useShopId'
import { useLocalStorage } from '@/modules/shared/utils/useLocalStorage'

export function useGetLocalRequisition() {
  const shopId = useShopId()
  const [localRequisition] = useLocalStorage<LocalRequisition>(`${LOCAL_REQUISITION_KEY}-${shopId}`, {
    lines: [],
    totalTaxValue: 0,
    totalValue: 0,
    totalValueExTax: 0,
  })
  return { requisition: localRequisition }
}
