import { useTranslation } from 'react-i18next'

import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import { OrderIcon } from '@/modules/shared/icons/OrderIcon'
import SideNavLink from '@/modules/shared/layouts/side-navigation/SideNavLink'
import { SideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/PurchaserNavigation'
import { Themes } from '@/modules/shared/types/Themes'

interface PurchaseOrdersSideBarItemProps extends SideBarItem {
  purchaseOrdersRoutesMatch: boolean
}

export const PurchaseOrdersSideBarItem = ({
  purchaseOrdersRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
  sideBarIndexKey,
}: PurchaseOrdersSideBarItemProps) => {
  const { t } = useTranslation()
  return (
    <Accordion
      titlePrefixIcon={<OrderIcon className="size-7" />}
      title={t('general.purchaseOrders', 'Purchase Orders')}
      buttonClassName="min-h-11 px-3"
      activeColor={purchaseOrdersRoutesMatch ? Themes.Primary : undefined}
      defaultOpen={openAccordionKey === sideBarIndexKey}
      handleTriggerClick={() => changeOpenAccordionKey(sideBarIndexKey)}
      keepActiveColor
    >
      <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="purchaseorder-navigation"
        sideNavLinkItems={[
          {
            key: t('general.viewAll', 'View All'),
            target: '/purchase-orders',
          },
        ]}
      />
    </Accordion>
  )
}
