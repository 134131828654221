import React from 'react'
import { PropsWithChildren } from 'react'

import { classNames } from '@/modules/shared/utils/classNames'

interface TileProps extends PropsWithChildren {
  className?: string
  testId?: string
}

// Tile inserts a divider line between each of its children
export const Tile = ({ className, children, testId }: TileProps) => {
  const items = React.Children.toArray(children)
  return (
    <>
      <div className={classNames('mb-6 rounded-md border bg-white', className)} data-testid={testId || 'tile-card'}>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            {item}
            {index < items.length - 1 && <hr />}
          </React.Fragment>
        ))}
      </div>
    </>
  )
}
