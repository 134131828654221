import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './public_graphql'

export const PurchasePlusPublicBaseErrorFragmentDoc = gql`
  fragment PurchasePlusPublicBaseError on BaseError {
    extensions {
      code
      status
    }
    message
  }
` as unknown as DocumentNode<Types.PurchasePlusPublicBaseErrorFragment, unknown>
