import { Route, Routes } from 'react-router-dom'

import PublicShop from '@/modules/public-shop/PublicShop'
import CartHeader from '@/modules/public-shop/cart/CartHeader'
import CartSummary from '@/modules/public-shop/cart/summary/CartSummary'
import ShopCheckout from '@/modules/public-shop/checkout/ShopCheckout'
import { ShopOrderPayment } from '@/modules/public-shop/shop-order-payment/ShopOrderPayment'
import SupplierPublicProducts from '@/modules/public-shop/supplier-public-products/SupplierPublicProducts'
import { ROUTE_PARAMS } from '@/routes/routes'

// This ShopRoutes is for both logged-in users and not logged-in users.
export default function ShopRoutes() {
  return (
    <Routes>
      <Route path="/" element={<PublicShop />} />
      <Route path={ROUTE_PARAMS.SHOP} element={<SupplierPublicProducts />} />
      <Route path={ROUTE_PARAMS.SHOP} element={<CartHeader />}>
        <Route path="summary" element={<CartSummary />} />
      </Route>
      <Route path={`${ROUTE_PARAMS.SHOP}/order/:orderUuid/payment`} element={<ShopOrderPayment />} />
      <Route path={`${ROUTE_PARAMS.SHOP}/checkout`} element={<ShopCheckout />} />
    </Routes>
  )
}
