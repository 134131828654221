import { zodResolver } from '@hookform/resolvers/zod'
import { Helmet } from 'react-helmet-async'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { CheckoutSummary } from '@/modules/public-shop/checkout/CheckoutSummary'
import {
  CheckoutDetailsFormInput,
  CheckoutDetailsSchema,
} from '@/modules/public-shop/checkout/checkout-form/CheckoutDetailsSchema'
import { CheckoutForm } from '@/modules/public-shop/checkout/checkout-form/CheckoutForm'
import { useCreatePayment } from '@/modules/public-shop/hooks/useCreatePayment'
import { useCreatePublicOrder } from '@/modules/public-shop/hooks/useCreatePublicOrder'
import { useGetLocalRequisition } from '@/modules/public-shop/hooks/useGetLocalRequisition'
import useGetPublicShop from '@/modules/public-shop/hooks/useGetPublicShop'
import { useSetShopPrimaryColor } from '@/modules/public-shop/hooks/useSetShopPrimaryColor'
import { useUpdateLocalRequisitionRequisition } from '@/modules/public-shop/hooks/useUpdateLocalRequisition'
import Button from '@/modules/shared/components/button/Button'
import { Container } from '@/modules/shared/components/container/Container'
import { QueryResult } from '@/modules/shared/components/query-result/QueryResult'
import { useAlert } from '@/modules/shared/hooks/useAlert'
import { ChevronLeftIcon } from '@/modules/shared/icons/ChevronLeftIcon'

export default function ShopCheckout() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { alertDialog } = useAlert()
  const { publicShop, loading, error } = useGetPublicShop()
  const { requisition } = useGetLocalRequisition()
  const [createPublicOrder, { loading: createPublicOrderLoading }] = useCreatePublicOrder()
  const [createPayment, { loading: createPaymentLoading }] = useCreatePayment()
  const { onResetLocalRequisition } = useUpdateLocalRequisitionRequisition()

  const formMethods = useForm<CheckoutDetailsFormInput>({
    resolver: zodResolver(CheckoutDetailsSchema),
    defaultValues: {
      referenceNumber: '',
      userEmail: '',
      deliveryAddressLine1: '',
      deliveryAddressLine2: '',
      orderInstructions: '',
      deliveryAddressCity: '',
      deliveryAddressCountry: '',
      deliveryAddressStateProvince: '',
      deliveryAddressPostalCode: '',
    },
  })
  useSetShopPrimaryColor()

  const onSubmit = (data: CheckoutDetailsFormInput) => {
    if (!publicShop) return
    const submitData = {
      ...data,
      supplierId: publicShop?.id,
      orderLines: requisition.lines.map((line) => ({
        cataloguedProductId: line.productId,
        quantity: line.quantity,
        orderInstruction: line.orderInstruction,
      })),
    }
    createPublicOrder({
      variables: {
        input: submitData,
      },
      onCompleted(data) {
        if (!data.createPublicOrder.errors?.length && data.createPublicOrder.order?.uuid) {
          const orderUuid = data.createPublicOrder.order?.uuid
          createPayment({
            variables: {
              input: {
                publicOrderUuid: orderUuid,
              },
            },
            onCompleted(data) {
              if (!data.createPayment.errors?.length && data.createPayment.paymentUrl) {
                const encodedPaymentUrl = encodeURIComponent(data.createPayment.paymentUrl)
                navigate(`/shop/${publicShop.id}/order/${orderUuid}/payment?payment-url=${encodedPaymentUrl}`)
                onResetLocalRequisition()
              } else {
                alertDialog({ type: 'error' })
              }
            },
          })
        } else {
          alertDialog({ type: 'error' })
        }
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{t('general.checkoutDetails', 'Checkout Details')}</title>
      </Helmet>
      <header className="px-4 py-3">
        <Button
          data-testid="action-return"
          onClick={() => navigate(-1)}
          className="flex h-11 w-fit items-center justify-between gap-x-2 rounded-md bg-gray-200 px-4"
        >
          <ChevronLeftIcon className="-ml-2 size-5" />
          <p className="text-sm">{t('general.goBack', 'Go Back')}</p>
        </Button>
      </header>
      <QueryResult loading={loading} error={error}>
        <Container layout="sm" className="py-10">
          <div className="flex flex-col">
            <h1 className="text-center text-2xl font-bold">{t('general.checkoutDetails', 'Checkout Details')}</h1>
            <p className="mt-2 text-center text-sm text-gray-500">
              {t('publicShop.checkoutDetail.description', 'Please complete the details below then proceed to payment.')}
            </p>
          </div>
          <form className="space-y-5" onSubmit={formMethods.handleSubmit(onSubmit)}>
            <FormProvider {...formMethods}>
              <CheckoutSummary />
              <CheckoutForm />
            </FormProvider>
            <Button
              loading={createPublicOrderLoading || createPaymentLoading}
              disabled={requisition.lines.length === 0}
              type="submit"
              data-testid="action-submit"
              className="mb-2 mt-8 block h-11 w-full rounded-md bg-primary px-3 text-center text-sm text-white"
            >
              {t('general.proceedToPayment', 'Proceed to Payment')}
            </Button>
          </form>
          <Button
            type="button"
            className="mb-2 mt-8 block h-11 w-full rounded-md bg-gray-200 px-3 text-center text-sm"
            onClick={() => navigate(-1)}
          >
            {t('confirmRequisition.back', 'Cancel and Go Back')}
          </Button>
        </Container>
      </QueryResult>
    </>
  )
}
