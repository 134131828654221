import { useMutation } from '@apollo/client'

import { CreatePublicOrderDocument } from '@/graphql/purchase-plus-public/generated/createPublicOrder.generated'
import { PURCHASE_PLUS_PUBLIC_GRAPHQL_API } from '@/modules/shared/constants'

export function useCreatePublicOrder() {
  const mutation = useMutation(CreatePublicOrderDocument, {
    context: {
      uri: PURCHASE_PLUS_PUBLIC_GRAPHQL_API,
    },
  })

  return mutation
}
