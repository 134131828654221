import { Maybe } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import { LOCAL_REQUISITION_KEY } from '@/modules/public-shop/hooks/useCreateLocalRequisition'
import { LocalRequisition, LocalRequisitionLine } from '@/modules/public-shop/types/requisitionLine'
import useShopId from '@/modules/shared/hooks/params/useShopId'
import { useLocalStorage } from '@/modules/shared/utils/useLocalStorage'

interface CreateRequisitionLinePayload {
  lineId: number
  productId: number
  unitPrice: number
  quantity: number
  taxPercentage: number
  product: {
    image: Maybe<string>
    longDescription: Maybe<string>
  }
}

interface UpdateRequisitionLinePayload {
  lineId?: number
  quantity?: number
  orderInstruction?: string | null
}

export function useUpdateLocalRequisitionRequisition() {
  const shopId = useShopId()

  const [localRequisition, setLocalRequisition] = useLocalStorage<LocalRequisition>(
    `${LOCAL_REQUISITION_KEY}-${shopId}`,
    { lines: [], totalTaxValue: 0, totalValueExTax: 0, totalValue: 0 }
  )

  const onCreateRequisitionLine = (payload: CreateRequisitionLinePayload) => {
    if (!localRequisition) return

    const requisition = localRequisition
    const linePayload = {
      id: payload.lineId,
      productId: payload.productId,
      unitPrice: payload.unitPrice,
      quantity: payload.quantity,
      taxPercentage: payload.taxPercentage,
      lineTotal: payload.unitPrice * payload.quantity,
      product: payload.product,
      orderInstruction: null,
    }
    requisition.lines.push(linePayload)
    requisition.totalValue = requisition.lines.reduce((acc, line) => acc + line.lineTotal, 0)
    requisition.totalTaxValue = requisition.lines.reduce(
      (acc, line) => acc + line.lineTotal * (line.taxPercentage / 100),
      0
    )
    requisition.totalValueExTax = requisition.totalValue - requisition.totalTaxValue
    setLocalRequisition(requisition)
  }

  const onUpdateRequisitionLine = (payload: UpdateRequisitionLinePayload) => {
    if (!localRequisition) return

    const requisition = localRequisition
    const lineIndex = requisition.lines.findIndex((line: LocalRequisitionLine) => line.id === payload.lineId)

    if (lineIndex >= 0) {
      if (payload.quantity) {
        requisition.lines[lineIndex].quantity = payload.quantity
        requisition.lines[lineIndex].lineTotal = requisition.lines[lineIndex].unitPrice * payload.quantity
        requisition.totalValue = requisition.lines.reduce((acc, line) => acc + line.lineTotal, 0)
      }

      if (payload.orderInstruction !== undefined) {
        requisition.lines[lineIndex].orderInstruction = payload.orderInstruction
      }
    }
    setLocalRequisition(requisition)
  }

  const onDeleteRequisitionLine = (lineId: number) => {
    if (!localRequisition) return

    const requisition = localRequisition
    const lines = requisition.lines.filter((line: LocalRequisitionLine) => line.id !== lineId)

    requisition.lines = lines
    requisition.totalValue = requisition.lines.reduce((acc, line) => acc + line.lineTotal, 0)
    requisition.totalTaxValue = requisition.lines.reduce(
      (acc, line) => acc + line.lineTotal * (line.taxPercentage / 100),
      0
    )
    requisition.totalValueExTax = requisition.totalValue - requisition.totalTaxValue
    setLocalRequisition(requisition)
  }

  const onResetLocalRequisition = () => {
    setLocalRequisition({ lines: [], totalTaxValue: 0, totalValueExTax: 0, totalValue: 0 })
  }

  return {
    onCreateRequisitionLine,
    onUpdateRequisitionLine,
    onDeleteRequisitionLine,
    onResetLocalRequisition,
  }
}
