export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: any; output: any; }
  Scalar: { input: any; output: any; }
};

/** A user-readable error. */
export type BaseError = {
  __typename?: 'BaseError';
  /** Any additional custom information. */
  extensions: Maybe<BaseErrorExtensions>;
  /** A description of the error. */
  message: Scalars['String']['output'];
  /** Which input value from where this error came. */
  path: Maybe<Array<Scalars['String']['output']>>;
};

/** Any additional information to provide with errors. */
export type BaseErrorExtensions = {
  __typename?: 'BaseErrorExtensions';
  /** HTTP status code. */
  code: Maybe<Scalars['Int']['output']>;
  /** Input parameters to the request. */
  inputParams: Maybe<Scalars['Scalar']['output']>;
  /** HTTP status message. */
  status: Maybe<Scalars['String']['output']>;
};

export type CataloguedProduct = {
  __typename?: 'CataloguedProduct';
  /** Unique identifier for the catalogued prodduct. */
  id: Scalars['Int']['output'];
  /** URL of the product's image. */
  image: Maybe<Scalars['String']['output']>;
  /** Long description of the product. */
  longDescription: Maybe<Scalars['String']['output']>;
  /** The product of the catalogued product. */
  product: Maybe<Product>;
  /** The selling price per unit of this product. */
  sellUnitPrice: Maybe<Scalars['Float']['output']>;
  /** The tax amount for this product. */
  sellUnitTax: Maybe<Scalars['Float']['output']>;
  /** The tax percentage for this product. */
  sellUnitTaxPercentage: Maybe<Scalars['Float']['output']>;
  /** The supplier's product code for this product. */
  supplierProductCode: Maybe<Scalars['String']['output']>;
};

/** The connection type for CataloguedProduct. */
export type CataloguedProductConnection = {
  __typename?: 'CataloguedProductConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CataloguedProductEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<CataloguedProduct>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CataloguedProductEdge = {
  __typename?: 'CataloguedProductEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CataloguedProduct>;
};

export type CategoriesQueryFiltersInput = {
  /** Whether to filter by categories that have a parent. */
  hasParent?: InputMaybe<Scalars['Boolean']['input']>;
  /** Name to filter by. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Parent id to filter by. */
  parentId?: InputMaybe<Scalars['Int']['input']>;
  /** Search text to filter by. */
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type CategoriesQuerySortsInput = {
  direction?: InputMaybe<SortDirectionEnum>;
  property?: InputMaybe<CategoriesQuerySortsPropertyEnum>;
};

export enum CategoriesQuerySortsPropertyEnum {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export type Category = {
  __typename?: 'Category';
  /** Returns all categories available to the current user. */
  categories: Maybe<CategoryConnection>;
  /** A detailed description of the category */
  description: Maybe<Scalars['String']['output']>;
  /** The unique identifier for the category */
  id: Scalars['Int']['output'];
  /** The name of the category */
  name: Maybe<Scalars['String']['output']>;
  /** The parent category, if any */
  parent: Maybe<Category>;
};


export type CategoryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<CategoriesQueryFiltersInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<Array<CategoriesQuerySortsInput>>;
};

/** The connection type for Category. */
export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CategoryEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Category>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Category>;
};

/** Autogenerated input type of CreatePayment */
export type CreatePaymentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  publicOrderUuid: Scalars['String']['input'];
};

/** Autogenerated return type of CreatePayment. */
export type CreatePaymentPayload = {
  __typename?: 'CreatePaymentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<BaseError>>;
  paymentUrl: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreatePublicOrder */
export type CreatePublicOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deliveryAddressCity: Scalars['String']['input'];
  deliveryAddressCountry: Scalars['String']['input'];
  deliveryAddressLine1: Scalars['String']['input'];
  deliveryAddressLine2?: InputMaybe<Scalars['String']['input']>;
  deliveryAddressPostalCode: Scalars['String']['input'];
  deliveryAddressStateProvince: Scalars['String']['input'];
  orderInstructions?: InputMaybe<Scalars['String']['input']>;
  orderLines: Array<OrderLineInput>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  supplierId: Scalars['Int']['input'];
  userEmail: Scalars['String']['input'];
};

/** Autogenerated return type of CreatePublicOrder. */
export type CreatePublicOrderPayload = {
  __typename?: 'CreatePublicOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<BaseError>>;
  order: Maybe<Order>;
};

export type IpsiTransaction = {
  __typename?: 'IpsiTransaction';
  /** the card token of the transaction */
  cardToken: Maybe<Scalars['String']['output']>;
  /** the masked pan of the transaction */
  maskedPan: Maybe<Scalars['String']['output']>;
  /** the merchant reference of the transaction */
  merchantReference: Scalars['String']['output'];
  /** the response code of the transaction */
  responseCode: Maybe<Scalars['String']['output']>;
  /** the response message of the transaction */
  responseMessage: Maybe<Scalars['String']['output']>;
  /** the state of the transaction */
  state: IpsiTransactionStateEnum;
  /** the transaction reference of the transaction */
  transactionReference: Maybe<Scalars['String']['output']>;
};

export enum IpsiTransactionStateEnum {
  Failed = 'failed',
  Pending = 'pending',
  Success = 'success'
}

export enum ItemMeasurementEnum {
  Gb = 'GB',
  Hz = 'Hz',
  Kb = 'KB',
  Kva = 'Kva',
  Mb = 'MB',
  MHz = 'MHz',
  Ns = 'Ns',
  Tb = 'TB',
  A10 = 'a10',
  Amp = 'amp',
  Bag = 'bag',
  Bale = 'bale',
  Bar = 'bar',
  Basket = 'basket',
  Block = 'block',
  Bottle = 'bottle',
  Box = 'box',
  Bunch = 'bunch',
  Bundle = 'bundle',
  Button = 'button',
  Can = 'can',
  Capsule = 'capsule',
  Carton = 'carton',
  Case = 'case',
  Catty = 'catty',
  Cc = 'cc',
  Cl = 'cl',
  Cm = 'cm',
  Cup = 'cup',
  Day = 'day',
  Dessertspoon = 'dessertspoon',
  Doz = 'doz',
  Dozen = 'dozen',
  Drawer = 'drawer',
  Drum = 'drum',
  Each = 'each',
  Fillet = 'fillet',
  Ft = 'ft',
  G = 'g',
  Gallon = 'gallon',
  Hole = 'hole',
  Hour = 'hour',
  Inch = 'inch',
  Jar = 'jar',
  Kg = 'kg',
  Km = 'km',
  L = 'l',
  Lbs = 'lbs',
  Leg = 'leg',
  Liter = 'liter',
  Loaf = 'loaf',
  Lt = 'lt',
  Ltr = 'ltr',
  M = 'm',
  Mg = 'mg',
  Minute = 'minute',
  Ml = 'ml',
  Mm = 'mm',
  Month = 'month',
  Ms = 'ms',
  Oct = 'oct',
  Octane = 'octane',
  Ohm = 'ohm',
  Oz = 'oz',
  Packet = 'packet',
  Pair = 'pair',
  Part = 'part',
  Phase = 'phase',
  Piece = 'piece',
  Pin = 'pin',
  Pint = 'pint',
  Point = 'point',
  Port = 'port',
  Portion = 'portion',
  Pound = 'pound',
  Punnet = 'punnet',
  Quart = 'quart',
  Ration = 'ration',
  Ream = 'ream',
  Roll = 'roll',
  Sachet = 'sachet',
  Second = 'second',
  Set = 'set',
  Sheet = 'sheet',
  Slab = 'slab',
  Slice = 'slice',
  Stem = 'stem',
  Stick = 'stick',
  Tablespoon = 'tablespoon',
  Tael = 'tael',
  Teaspoon = 'teaspoon',
  Tin = 'tin',
  Tonne = 'tonne',
  Tray = 'tray',
  Tub = 'tub',
  Tube = 'tube',
  V = 'v',
  Volt = 'volt',
  Watt = 'watt',
  Way = 'way',
  Week = 'week',
  Yard = 'yard',
  Year = 'year'
}

export enum ItemPackNameEnum {
  Bag = 'bag',
  Bale = 'bale',
  Bar = 'bar',
  Barrel = 'barrel',
  Basket = 'basket',
  Bib = 'bib',
  Bin = 'bin',
  Bladder = 'bladder',
  Block = 'block',
  Book = 'book',
  Bottle = 'bottle',
  Bowl = 'bowl',
  Box = 'box',
  Brick = 'brick',
  Brochure = 'brochure',
  Bucket = 'bucket',
  Bunch = 'bunch',
  Bundle = 'bundle',
  Can = 'can',
  Canister = 'canister',
  Capsule = 'capsule',
  Carton = 'carton',
  Cartridge = 'cartridge',
  Case = 'case',
  Cask = 'cask',
  CateringPack = 'catering_pack',
  Cone = 'cone',
  Container = 'container',
  Crate = 'crate',
  Cube = 'cube',
  Cup = 'cup',
  Cylinder = 'cylinder',
  Dish = 'dish',
  Dispenser = 'dispenser',
  Drum = 'drum',
  Each = 'each',
  Envelope = 'envelope',
  Fillet = 'fillet',
  Flagon = 'flagon',
  Flask = 'flask',
  Inner = 'inner',
  Jar = 'jar',
  JerryCan = 'jerry_can',
  Keg = 'keg',
  Layer = 'layer',
  Leg = 'leg',
  Length = 'length',
  Loaf = 'loaf',
  Log = 'log',
  Pack = 'pack',
  Packet = 'packet',
  Pair = 'pair',
  Pallet = 'pallet',
  Pc = 'pc',
  Per = 'per',
  Piece = 'piece',
  Portion = 'portion',
  Punnet = 'punnet',
  Rack = 'rack',
  Ream = 'ream',
  Roll = 'roll',
  Sack = 'sack',
  Serve = 'serve',
  Serving = 'serving',
  Slab = 'slab',
  Sleeve = 'sleeve',
  Slice = 'slice',
  Spiral = 'spiral',
  Spool = 'spool',
  Tablet = 'tablet',
  Tin = 'tin',
  Tray = 'tray',
  Tub = 'tub',
  Tube = 'tube',
  Unit = 'unit',
  Vial = 'vial',
  Wedge = 'wedge',
  Whole = 'whole'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Creates a payment */
  createPayment: CreatePaymentPayload;
  /** Creates a public order */
  createPublicOrder: CreatePublicOrderPayload;
  /** Updates a payment */
  updatePayment: UpdatePaymentPayload;
};


export type MutationCreatePaymentArgs = {
  input: CreatePaymentInput;
};


export type MutationCreatePublicOrderArgs = {
  input: CreatePublicOrderInput;
};


export type MutationUpdatePaymentArgs = {
  input: UpdatePaymentInput;
};

export type Order = {
  __typename?: 'Order';
  /** the delivery address city of the order */
  deliveryAddressCity: Scalars['String']['output'];
  /** the delivery address country of the order */
  deliveryAddressCountry: Scalars['String']['output'];
  /** the delivery address line 1 of the order */
  deliveryAddressLine1: Scalars['String']['output'];
  /** the delivery address line 2 of the order */
  deliveryAddressLine2: Scalars['String']['output'];
  /** the delivery address postal code of the order */
  deliveryAddressPostalCode: Scalars['String']['output'];
  /** the delivery address state province of the order */
  deliveryAddressStateProvince: Scalars['String']['output'];
  /** the order instructions of the order */
  orderInstructions: Scalars['String']['output'];
  /** the lines of the order */
  orderLines: Maybe<OrderLineConnection>;
  /** the payment status of the order */
  paymentStatus: OrderPaymentStatusEnum;
  /** the reference number of the order */
  referenceNumber: Scalars['String']['output'];
  /** the status of the order */
  status: OrderStatusEnum;
  /** the supplier of the order */
  supplier: Supplier;
  /** the user who created the order */
  user: PublicUser;
  /** the UUID of the order */
  uuid: Scalars['String']['output'];
};


export type OrderOrderLinesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Order. */
export type OrderConnection = {
  __typename?: 'OrderConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<OrderEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Order>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrderEdge = {
  __typename?: 'OrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Order>;
};

export type OrderLine = {
  __typename?: 'OrderLine';
  /** the catalogued product */
  cataloguedProduct: CataloguedProduct;
  /** the description of the order line */
  description: Scalars['String']['output'];
  /** the line tax of the order line */
  lineTax: Scalars['Float']['output'];
  /** the line total of the order line */
  lineTotal: Scalars['Float']['output'];
  /** the shop order this belongs to */
  order: Order;
  /** the order instruction of the order line */
  orderInstruction: Scalars['String']['output'];
  /** the quantity of the order line */
  quantity: Scalars['Int']['output'];
  /** the tax amount of the order line */
  taxAmount: Scalars['Float']['output'];
  /** the tax percentage of the order line */
  taxPercentage: Scalars['Float']['output'];
  /** the unit price of the order line */
  unitPrice: Scalars['Float']['output'];
  /** the UUID of the order line */
  uuid: Scalars['String']['output'];
};

/** The connection type for OrderLine. */
export type OrderLineConnection = {
  __typename?: 'OrderLineConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<OrderLineEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<OrderLine>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrderLineEdge = {
  __typename?: 'OrderLineEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<OrderLine>;
};

export type OrderLineInput = {
  cataloguedProductId: Scalars['Int']['input'];
  orderInstruction?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
};

export enum OrderPaymentStatusEnum {
  Paid = 'paid',
  Unpaid = 'unpaid'
}

export enum OrderStatusEnum {
  Acknowledged = 'acknowledged',
  Cancelled = 'cancelled',
  Delivered = 'delivered',
  Draft = 'draft',
  Shipped = 'shipped',
  Submitted = 'submitted'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

export type Product = {
  __typename?: 'Product';
  /** The brand of the product. */
  brand: Maybe<Scalars['String']['output']>;
  /** The canonical product ID of the product. */
  canonicalProductId: Maybe<Scalars['Int']['output']>;
  /** The category of the product. */
  category: Maybe<Category>;
  /** The concatenated description of the product. */
  concatenatedDescription: Maybe<Scalars['String']['output']>;
  /** The concatenated sell unit of the product. */
  concatenatedSellUnit: Maybe<Scalars['String']['output']>;
  /** The default sale price of the product. */
  defaultSalePrice: Maybe<Scalars['Float']['output']>;
  /** The external ID of the product. */
  externalId: Maybe<Scalars['String']['output']>;
  /** The ID of the product. */
  id: Scalars['Int']['output'];
  /** The image of the product. */
  image: Maybe<Scalars['String']['output']>;
  /** The item description of the product. */
  itemDescription: Maybe<Scalars['String']['output']>;
  /** The item measure of the product. */
  itemMeasure: Maybe<Scalars['String']['output']>;
  /** The item measure enum values of the product. */
  itemMeasureEnumValues: Maybe<Array<ItemMeasurementEnum>>;
  /** The item pack name of the product. */
  itemPackName: Maybe<Scalars['String']['output']>;
  /** The item pack name enum values of the product. */
  itemPackNameEnumValues: Maybe<Array<ItemPackNameEnum>>;
  /** The item sell pack name of the product. */
  itemSellPackName: Maybe<Scalars['String']['output']>;
  /** The item sell quantity of the product. */
  itemSellQuantity: Maybe<Scalars['Float']['output']>;
  /** The item size of the product. */
  itemSize: Maybe<Scalars['Float']['output']>;
  /** The locale of the product. */
  locale: Scalars['String']['output'];
  /** The owner of the product. */
  owner: Maybe<Supplier>;
  /** The owner code of the product. */
  ownerCode: Maybe<Scalars['String']['output']>;
  /** The product code of the product. */
  productCode: Maybe<Scalars['String']['output']>;
  /** The volume in litres of the product. */
  volumeInLitres: Maybe<Scalars['Float']['output']>;
};


export type ProductProductCodeArgs = {
  organisationId?: InputMaybe<Scalars['Int']['input']>;
};

export type PublicCataloguedProductsQueryFiltersInput = {
  /** The text to search for in the catalogued products. */
  searchText?: InputMaybe<Scalars['String']['input']>;
  /** The supplier product code to filter by. */
  supplierProductCode?: InputMaybe<Scalars['String']['input']>;
};

export type PublicCataloguedProductsQuerySortsInput = {
  direction?: InputMaybe<SortDirectionEnum>;
  property?: InputMaybe<PublicCataloguedProductsQuerySortsPropertyEnum>;
};

export enum PublicCataloguedProductsQuerySortsPropertyEnum {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export type PublicShopsQueryFiltersInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PublicShopsQuerySortsInput = {
  direction?: InputMaybe<SortDirectionEnum>;
  property?: InputMaybe<PublicShopsQuerySortsPropertyEnum>;
};

export enum PublicShopsQuerySortsPropertyEnum {
  CreatedAt = 'created_at',
  Name = 'name'
}

export type PublicUser = {
  __typename?: 'PublicUser';
  /** the city of the user */
  addressCity: Scalars['String']['output'];
  /** the country of the user */
  addressCountry: Scalars['String']['output'];
  /** the address line 1 of the user */
  addressLine1: Scalars['String']['output'];
  /** the address line 2 of the user */
  addressLine2: Scalars['String']['output'];
  /** the postal code of the user */
  addressPostalCode: Scalars['String']['output'];
  /** the state province of the user */
  addressStateProvince: Scalars['String']['output'];
  /** the email of the user */
  email: Scalars['String']['output'];
  /** the full name of the user */
  fullName: Maybe<Scalars['String']['output']>;
  /** the orders of the user */
  orders: OrderConnection;
  /** the phone number of the user */
  phoneNumber: Scalars['String']['output'];
  /** the UUID of the user */
  uuid: Scalars['String']['output'];
};


export type PublicUserOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type Query = {
  __typename?: 'Query';
  /** Fetches the public shop of a given supplier. */
  publicShop: Maybe<Supplier>;
  /** Returns all public shops available to the current user. */
  publicShops: Maybe<SupplierConnection>;
  /** Fetches the public user of a given supplier. */
  publicUser: Maybe<PublicUser>;
};


export type QueryPublicShopArgs = {
  id: Scalars['Int']['input'];
};


export type QueryPublicShopsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<PublicShopsQueryFiltersInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<Array<PublicShopsQuerySortsInput>>;
};


export type QueryPublicUserArgs = {
  uuid: Scalars['String']['input'];
};

export enum SortDirectionEnum {
  /** Ascending Order */
  Asc = 'ASC',
  /** Descending Order */
  Desc = 'DESC'
}

export type Supplier = {
  __typename?: 'Supplier';
  /** The billing address city of the supplier. */
  billingAddressCity: Maybe<Scalars['String']['output']>;
  /** The billing address two letter country code of the supplier. */
  billingAddressCountry: Maybe<Scalars['String']['output']>;
  /** Indicates that the billing address and postal address are the same. */
  billingAddressIsPostalAddress: Maybe<Scalars['Boolean']['output']>;
  /** The billing address first line of the supplier. */
  billingAddressLine1: Maybe<Scalars['String']['output']>;
  /** The billing address second line of the supplier. */
  billingAddressLine2: Maybe<Scalars['String']['output']>;
  /** The billing address postal code of the supplier. */
  billingAddressPostalCode: Maybe<Scalars['String']['output']>;
  /** The billing address state or province of the supplier. */
  billingAddressStateProvince: Maybe<Scalars['String']['output']>;
  /** The email address any billing enquiries should be sent to. */
  billingEmail: Maybe<Scalars['String']['output']>;
  /** The government assigned business number. ABN in Australia for example. */
  businessNumber: Maybe<Scalars['String']['output']>;
  /** The date and time the supplier was created. */
  createdAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The ID of the supplier. */
  id: Scalars['Int']['output'];
  /** The name of the supplier. */
  name: Maybe<Scalars['String']['output']>;
  /** Returns all public catalogued products available to the current user. */
  publicCataloguedProducts: Maybe<CataloguedProductConnection>;
  /** The URL of the shop banner image. */
  shopBannerUrl: Maybe<Scalars['String']['output']>;
  /** The hex code of the shop. */
  shopHexCode: Maybe<Scalars['String']['output']>;
  /** The URL of the shop overlay logo image. */
  shopOverlayLogoUrl: Maybe<Scalars['String']['output']>;
  /** The URL of the shop square logo image. */
  shopSquareLogoUrl: Maybe<Scalars['String']['output']>;
  /** A short description of the supplier. */
  shortDescription: Maybe<Scalars['String']['output']>;
  /** The telephone number of the supplier. */
  telephone: Maybe<Scalars['String']['output']>;
  /** The date and time this organisation was updated. */
  updatedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
};


export type SupplierPublicCataloguedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<PublicCataloguedProductsQueryFiltersInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<Array<PublicCataloguedProductsQuerySortsInput>>;
};

/** The connection type for Supplier. */
export type SupplierConnection = {
  __typename?: 'SupplierConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<SupplierEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Supplier>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SupplierEdge = {
  __typename?: 'SupplierEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Supplier>;
};

/** Autogenerated input type of UpdatePayment */
export type UpdatePaymentInput = {
  amount: Scalars['Float']['input'];
  cardHolderName: Scalars['String']['input'];
  cardToken: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  configId: Scalars['String']['input'];
  maskedPan: Scalars['String']['input'];
  merchReference: Scalars['String']['input'];
  message: Scalars['String']['input'];
  responseCode: Scalars['String']['input'];
  txnReference: Scalars['String']['input'];
  userName: Scalars['String']['input'];
  verifyMessage: Scalars['String']['input'];
};

/** Autogenerated return type of UpdatePayment. */
export type UpdatePaymentPayload = {
  __typename?: 'UpdatePaymentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<BaseError>>;
  ipsiTransaction: IpsiTransaction;
};

export type PurchasePlusPublicBaseErrorFragment = { __typename?: 'BaseError', message: string, extensions: { __typename?: 'BaseErrorExtensions', code: number | null, status: string | null } | null };

export type PublicPageInfoFragment = { __typename?: 'PageInfo', startCursor: string | null, endCursor: string | null, hasPreviousPage: boolean, hasNextPage: boolean };

export type CreatePaymentMutationVariables = Exact<{
  input: CreatePaymentInput;
}>;


export type CreatePaymentMutation = { __typename?: 'Mutation', createPayment: { __typename?: 'CreatePaymentPayload', paymentUrl: string | null, errors: Array<{ __typename?: 'BaseError', message: string, extensions: { __typename?: 'BaseErrorExtensions', code: number | null, status: string | null } | null }> | null } };

export type CreatePublicOrderMutationVariables = Exact<{
  input: CreatePublicOrderInput;
}>;


export type CreatePublicOrderMutation = { __typename?: 'Mutation', createPublicOrder: { __typename?: 'CreatePublicOrderPayload', order: { __typename?: 'Order', uuid: string } | null, errors: Array<{ __typename?: 'BaseError', message: string, extensions: { __typename?: 'BaseErrorExtensions', code: number | null, status: string | null } | null }> | null } };

export type GetPublicShopQueryVariables = Exact<{
  publicShopId: Scalars['Int']['input'];
}>;


export type GetPublicShopQuery = { __typename?: 'Query', publicShop: { __typename?: 'Supplier', id: number, name: string | null, telephone: string | null, billingAddressCity: string | null, billingAddressCountry: string | null, billingAddressIsPostalAddress: boolean | null, billingAddressLine1: string | null, billingAddressLine2: string | null, billingAddressPostalCode: string | null, billingAddressStateProvince: string | null, billingEmail: string | null, shortDescription: string | null, businessNumber: string | null, shopBannerUrl: string | null, shopHexCode: string | null, shopOverlayLogoUrl: string | null, shopSquareLogoUrl: string | null } | null };

export type GetPublicShopProductsQueryVariables = Exact<{
  publicShopId: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<PublicCataloguedProductsQueryFiltersInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<Array<PublicCataloguedProductsQuerySortsInput> | PublicCataloguedProductsQuerySortsInput>;
}>;


export type GetPublicShopProductsQuery = { __typename?: 'Query', publicShop: { __typename?: 'Supplier', id: number, publicCataloguedProducts: { __typename?: 'CataloguedProductConnection', edges: Array<{ __typename?: 'CataloguedProductEdge', node: { __typename?: 'CataloguedProduct', id: number, image: string | null, longDescription: string | null, sellUnitPrice: number | null, sellUnitTax: number | null, sellUnitTaxPercentage: number | null, supplierProductCode: string | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', startCursor: string | null, endCursor: string | null, hasPreviousPage: boolean, hasNextPage: boolean } } | null } | null };

export type GetPublicShopsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<PublicShopsQueryFiltersInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<Array<PublicShopsQuerySortsInput> | PublicShopsQuerySortsInput>;
}>;


export type GetPublicShopsQuery = { __typename?: 'Query', publicShops: { __typename?: 'SupplierConnection', edges: Array<{ __typename?: 'SupplierEdge', node: { __typename?: 'Supplier', id: number, name: string | null, shortDescription: string | null, shopBannerUrl: string | null, shopHexCode: string | null, shopOverlayLogoUrl: string | null, shopSquareLogoUrl: string | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', startCursor: string | null, endCursor: string | null, hasPreviousPage: boolean, hasNextPage: boolean } } | null };
