import { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import AppLayout from '@/modules/shared/layouts/app-layout/AppLayout'
import NotFound from '@/modules/shared/pages/not-found/NotFound'

// Inside app layout
const SupplierSystemSettingsRoutes = lazy(() => import('@/routes/shared-routes/SupplierSystemSettingsRoutes'))
const UserRoutes = lazy(() => import('@/routes/shared-routes/UserRoutes'))
const MyAccountRoutes = lazy(() => import('@/routes/shared-routes/MyAccountRoutes'))
const SupplierProductRoutes = lazy(() => import('@/routes/shared-routes/SupplierProductRoutes'))
const PurchaserDirectoryRoutes = lazy(() => import('@/routes/shared-routes/PurchaserDirectoryRoutes'))
const MyPurchasersRoutes = lazy(() => import('@/routes/shared-routes/MyPurchasersRoutes'))
const UtilityRoutes = lazy(() => import('@/routes/shared-routes/UtilityRoutes'))
const ShopRoutes = lazy(() => import('@/routes/public-routes/ShopRoutes'))
const BarcodeRoutes = lazy(() => import('@/routes/shared-routes/BarcodeRoutes'))
const Dashboard = lazy(() => import('@/modules/supplier/pages/dashboard/Dashboard'))
const SupplierCatalogRoutes = lazy(() => import('@/routes/shared-routes/SupplierCatalogRoutes'))
const SupplierPurchaseOrdersRoutes = lazy(() => import('@/routes/shared-routes/SupplierPurchaseOrdersRoutes'))
const SupplierInvoicesRoutes = lazy(() => import('@/routes/shared-routes/SupplierInvoicesRoutes'))

export default function SupplierRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" />} />

      <Route element={<AppLayout />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/purchaser-directory/*" element={<PurchaserDirectoryRoutes />} />
        <Route path="/my-purchasers/*" element={<MyPurchasersRoutes />} />
        <Route path="/my-account/*" element={<MyAccountRoutes />} />
        <Route path="/products/*" element={<SupplierProductRoutes />} />
        <Route path="/catalogs/*" element={<SupplierCatalogRoutes />} />
        <Route path="/barcodes/*" element={<BarcodeRoutes />} />
        <Route path="/users/*" element={<UserRoutes isSupplier />} />
        <Route path="/settings/*" element={<SupplierSystemSettingsRoutes />} />
        <Route path="/shop/*" element={<ShopRoutes />} />
        <Route path="/utilities/*" element={<UtilityRoutes />} />
        <Route path="/purchase-orders/*" element={<SupplierPurchaseOrdersRoutes />} />
        <Route path="/invoices/*" element={<SupplierInvoicesRoutes />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
