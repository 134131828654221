import { useTranslation } from 'react-i18next'
import { StringParam, useQueryParam } from 'use-query-params'

export function ShopOrderPayment() {
  const { t } = useTranslation()
  const [paymentUrl] = useQueryParam('payment-url', StringParam)

  if (!paymentUrl) {
    return <div>{t('ipsiPayment.paymentUrlMissing', 'Payment URL is missing')}</div>
  }

  return <iframe src={paymentUrl} className="size-full min-h-screen" />
}
