import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { InvoiceLineItem, Product, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Fallback } from '@/modules/shared/components/fallback/Fallback'
import { ImageItem } from '@/modules/shared/components/image/ImageItem'
import ProductModal, { Line } from '@/modules/shared/components/product-modal/ProductModal'
import { ProductItem } from '@/modules/shared/components/table/table-column-items/shared/ProductItem'
import { useMoney } from '@/modules/shared/hooks/useMoney'

export interface TileTitleAndTotalProps {
  title?: string | null
  subtitle?: string | null
  total?: number | null
  totalTaxValue?: number | null
  /** if a line is provided it will show the product details instead of a title and subtitle */
  line?: Line | null | undefined
  supplier?: Supplier
  receivingTotal?: boolean
  receivingUnitPrice?: boolean
  currency?: string
}
export const TileTitleAndTotal = ({
  title,
  subtitle,
  total,
  totalTaxValue,
  line,
  supplier,
  receivingTotal,
  receivingUnitPrice,
  currency,
}: TileTitleAndTotalProps) => {
  const { formatMoney } = useMoney()
  const { t } = useTranslation()
  const { image, brand, itemDescription } = line?.product || {}
  const [showProductModal, setShowProductModal] = useState(false)

  const handleRightSubtitle = () => {
    if (receivingTotal) {
      return t('general.receivingTotal', 'Receiving Total')
    } else if (receivingUnitPrice) {
      return t('general.receivingUnitPrice', 'Receiving Unit Price')
    } else if (totalTaxValue || totalTaxValue === 0) {
      return t('general.plusTaxOf', 'Plus {{ totalTaxValue }} Tax', {
        totalTaxValue: formatMoney({ amount: totalTaxValue, customCurrency: currency }),
      })
    }
  }

  return (
    <div className="flex justify-between p-4">
      {line ? (
        <div className="flex cursor-pointer flex-row items-center" onClick={() => setShowProductModal(true)}>
          <ImageItem
            src={image}
            alt={`${brand} ${itemDescription}`}
            className="mr-4 max-h-[3.75rem] min-h-[3.75rem] min-w-[3.75rem] max-w-[3.75rem] border border-gray-200"
          />
          <div className="pr-4 text-sm leading-5">
            <ProductItem product={line.product as Product} supplier={supplier} line={line as InvoiceLineItem} />
          </div>
        </div>
      ) : (
        <div>
          <span className="break-all text-sm font-bold sm:text-base" data-testid="tile-title">
            <Fallback condition={!!title}>{title}</Fallback>
          </span>
          <p className="text-xs text-gray-500 sm:text-sm" data-testid="tile-subtitle">
            <Fallback condition={!!subtitle}>{subtitle}</Fallback>
          </p>
        </div>
      )}
      {(total || total === 0) && (
        <div className="flex items-center">
          <div className="mt-0 w-32 text-right">
            <span className="text-sm font-bold">{formatMoney({ amount: total, customCurrency: currency })}</span>
            <p className="text-xs text-gray-500">{handleRightSubtitle()}</p>
          </div>
        </div>
      )}
      {line && (
        <ProductModal line={line} showModal={showProductModal} setShowModal={setShowProductModal} supplier={supplier} />
      )}
    </div>
  )
}
