import { LocalRequisition } from '@/modules/public-shop/types/requisitionLine'
import useShopId from '@/modules/shared/hooks/params/useShopId'
import { useLocalStorage } from '@/modules/shared/utils/useLocalStorage'

export const LOCAL_REQUISITION_KEY = 'local-requisition'

export function useCreateLocalnRequisition() {
  const shopId = useShopId()
  useLocalStorage<LocalRequisition>(`${LOCAL_REQUISITION_KEY}-${shopId}`, {
    lines: [],
    totalTaxValue: 0,
    totalValue: 0,
    totalValueExTax: 0,
  })
}
