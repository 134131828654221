import { useTranslation } from 'react-i18next'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'

import { useGetLocalRequisition } from '@/modules/public-shop/hooks/useGetLocalRequisition'
import useGetPublicShop from '@/modules/public-shop/hooks/useGetPublicShop'
import Button from '@/modules/shared/components/button/Button'
import { Container } from '@/modules/shared/components/container/Container'
import HeaderTabs, { TabItem } from '@/modules/shared/components/header-tabs/HeaderTabs'
import { MoreOptionsMenu } from '@/modules/shared/components/more-options/MoreOptionsMenu'
import { QueryResult } from '@/modules/shared/components/query-result/QueryResult'
import { ThemeWrapper } from '@/modules/shared/components/theme-wrapper/ThemeWrapper'
import { HELP_LINK_PUBLIC_SHOP_CART_VIEW_MORE_OPTION } from '@/modules/shared/help'
import useShopId from '@/modules/shared/hooks/params/useShopId'
import { useMoney } from '@/modules/shared/hooks/useMoney'
import { CartIcon } from '@/modules/shared/icons/CartIcon'
import { ChevronLeftIcon } from '@/modules/shared/icons/ChevronLeftIcon'
import { ChevronRightIcon } from '@/modules/shared/icons/ChevronRightIcon'
import { MoreOptionsItem } from '@/modules/shared/types/MoreOptionsItem'
import { Themes } from '@/modules/shared/types/Themes'

export default function CartHeader() {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const location = useLocation()
  const navigate = useNavigate()
  const { loading, error } = useGetPublicShop()
  const { requisition } = useGetLocalRequisition()

  const shopId = useShopId()

  const actionList: TabItem[] = [{ target: `/shop/${shopId}/summary`, label: t('general.cartSummary', 'Cart Summary') }]

  const onProceedToCheckout = () => {
    navigate(`/shop/${shopId}/checkout`)
  }

  return (
    <>
      <header className="flex items-center justify-between border-b bg-white px-5 py-3">
        <Link
          className="flex h-11 w-fit items-center justify-between gap-x-2 rounded-md bg-gray-100 px-4 transition hover:brightness-95"
          to={`/shop/${shopId}${location.search}`}
          data-testid="header-return-to-all-suppliers"
        >
          <ChevronLeftIcon className="-ml-2 size-5" />
          <span className="text-sm">{t('general.continuteShopping', 'Continue Shopping')}</span>
        </Link>
        <MoreOptionsMenu
          buttonTheme="dark"
          iconClass="size-9"
          components={[MoreOptionsItem.Help]}
          helpLink={HELP_LINK_PUBLIC_SHOP_CART_VIEW_MORE_OPTION}
        />
      </header>
      <QueryResult loading={loading} error={error}>
        <Container layout="md" className="py-4">
          <div className="flex flex-col gap-y-2">
            <section className="flex w-full flex-col justify-between rounded-md bg-white shadow sm:flex-row">
              <div className="flex items-center gap-4 p-4">
                <ThemeWrapper theme={Themes.Primary} className="flex size-14 items-center justify-center rounded-md">
                  <CartIcon className="size-10" />
                </ThemeWrapper>
                <div>
                  <p className="font-bold" data-testid="page-header-title">
                    {formatMoney({ amount: requisition.totalValue })}
                  </p>
                  <p className="text-sm text-gray-500" data-testid="page-header-subtitle">
                    {t('requisitionInformation.cart.plusTaxOf', 'Plus Tax of {{ totalTaxValue }}', {
                      totalTaxValue: formatMoney({ amount: requisition.totalTaxValue }),
                    })}
                  </p>
                </div>
              </div>
              <hr className="sm:hidden" />
              <div className="flex items-center p-4">
                <Button
                  disabled={requisition.lines.length === 0}
                  className="flex h-11 w-full items-center justify-between gap-1 rounded-md bg-primary px-4 text-sm text-white"
                  onClick={() => onProceedToCheckout()}
                  data-testid="proceed-to-checkout-btn"
                >
                  <span>{t('general.proceedToCheckout', 'Proceed to Checkout')}</span>
                  <ChevronRightIcon className="size-6" />
                </Button>
              </div>
            </section>
            <HeaderTabs actionList={actionList} />
          </div>
          <Outlet />
        </Container>
      </QueryResult>
    </>
  )
}
