import ReactGA from 'react-ga4'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import ApolloProviderWrapper from '@/apollo/ApolloProviderWrapper'
import i18nextConfig from '@/locales/i18nextConfig'
import TermsAndConditions from '@/modules/access/components/terms-and-conditions/TermsAndConditions'
import { HubSpotWrapper } from '@/modules/hubspot/HubSpotWrapper'
import AlertDialog from '@/modules/shared/components/alert-dialog/AlertDialog'
import { ScrollToTop } from '@/modules/shared/components/scroll-to-top/ScrollToTop'
import { SentryAdminWrapper } from '@/modules/shared/components/sentry-admin-wrapper/SentryAdminWrapper'
import { ToastContainerItem } from '@/modules/shared/components/toast/ToastContainerItem'
import { AlertProvider } from '@/modules/shared/providers/AlertProvider'
import ReactAriaI18nProvider from '@/modules/shared/providers/ReactAriaI18nProvider'
import AllRoutes from '@/routes/all-routes/AllRoutes'

i18nextConfig()

if (import.meta.env.VITE_APP_GA4_MEASUREMENT_ID) {
  ReactGA.initialize(import.meta.env.VITE_APP_GA4_MEASUREMENT_ID)
  ReactGA.send('pageview')
}

function App() {
  return (
    <AlertProvider>
      <ApolloProviderWrapper>
        <SentryAdminWrapper>
          <ReactAriaI18nProvider>
            <HelmetProvider>
              <BrowserRouter>
                <HubSpotWrapper>
                  <ScrollToTop />
                  <TermsAndConditions />
                  <QueryParamProvider adapter={ReactRouter6Adapter}>
                    <AllRoutes />
                    <ToastContainerItem />
                  </QueryParamProvider>
                </HubSpotWrapper>
              </BrowserRouter>
            </HelmetProvider>
            <AlertDialog />
          </ReactAriaI18nProvider>
        </SentryAdminWrapper>
      </ApolloProviderWrapper>
    </AlertProvider>
  )
}

export default App
