import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CheckoutDeliveryAddress from '@/modules/public-shop/checkout/checkout-form/CheckoutDeliveryAddress'
import FormInput from '@/modules/shared/components/create-form/FormInput'

export function CheckoutForm() {
  const { t } = useTranslation()
  const formMethods = useFormContext()
  return (
    <>
      <FormInput
        title={t('general.reference', 'Reference')}
        register="referenceNumber"
        placeholder={t('general.reference', 'Reference')}
        description={t(
          'publicShop.checkoutDetails.reference',
          'A brief description of the Order. The reference will be appear on the Order and will be visible to the Supplier.'
        )}
      />
      <FormInput
        title={t('general.emailAddress', 'Email Address')}
        register="userEmail"
        placeholder={t('general.emailAddress', 'Email Address')}
        description={t(
          'publicShop.checkoutDetails.emailAddress',
          'An order confirmation will be sent to this email address.'
        )}
      />
      <CheckoutDeliveryAddress />
      <section className="mt-4">
        <h2 className="text-sm font-semibold">{t('general.orderInstruction', 'Order Instruction')}</h2>
        <p className="text-sm text-gray-500">
          {t('publicShop.checkoutDetails.orderInstructionExplain', 'Add order instructions here for this order')}
        </p>
        <textarea
          {...formMethods.register('orderInstructions')}
          data-testid="order-instruction-input"
          className="mt-4 w-full rounded-md border border-gray-300 p-3 text-sm shadow-sm focus:outline-none focus:ring-primary"
          rows={6}
          placeholder={t('general.orderInstruction', 'Order Instruction')}
        ></textarea>
      </section>
    </>
  )
}
