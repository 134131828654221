import { useTranslation } from 'react-i18next'

import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import { InvoiceIcon } from '@/modules/shared/icons/InvoiceIcon'
import SideNavLink from '@/modules/shared/layouts/side-navigation/SideNavLink'
import { SideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/PurchaserNavigation'
import { Themes } from '@/modules/shared/types/Themes'

interface InvoicesSideBarItemProps extends SideBarItem {
  invoiceRoutesMatch: boolean
}

export const InvoicesSideBarItem = ({
  invoiceRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
  sideBarIndexKey,
}: InvoicesSideBarItemProps) => {
  const { t } = useTranslation()

  return (
    <Accordion
      titlePrefixIcon={<InvoiceIcon className="size-7" />}
      title={t('general.invoices', 'Invoices')}
      activeColor={invoiceRoutesMatch ? Themes.Primary : undefined}
      defaultOpen={openAccordionKey === sideBarIndexKey}
      handleTriggerClick={() => changeOpenAccordionKey(sideBarIndexKey)}
      buttonClassName="min-h-11 px-3"
      keepActiveColor
    >
      <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="invoice-navigation"
        sideNavLinkItems={[
          {
            key: t('general.viewAll', 'View All'),
            target: '/invoices',
          },
        ]}
      />
    </Accordion>
  )
}
