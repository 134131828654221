import { PropsWithChildren } from 'react'
import { Link, generatePath } from 'react-router-dom'

import useSessionUser from '@/modules/access/hooks/useSessionUser'

interface PPNewDetailLinkProps extends PropsWithChildren {
  id?: string
  secondaryId?: string // for use on links that require two ids
  type?:
    | 'purchase-order'
    | 'requisition'
    | 'invoice'
    | 'credit-note'
    | 'catalog'
    | 'catalog-product'
    | 'supplier'
    | 'supplier-directory'
    | 'supplier-directory-products'
    | 'user'
    | 'buy-list'
    | 'stocktake'
    | 'transfer'
    | 'receiving-document'
    | 'utility-organisation'
    | 'utility-ap-system'
    | 'utility-catalog'
    | 'utility-trade-relationship'
    | 'utility-user'
    | 'utility-hierarchy'
    | 'utility-ap-export-format'
    | 'point-of-sale-batch'
  testId?: string
  openNewTab?: boolean
  className?: string
  advanced?: boolean
  isUtility?: boolean
}

export function PPNewDetailLink({
  id,
  secondaryId,
  type,
  openNewTab = false,
  children,
  className,
  testId,
  isUtility = false,
}: PPNewDetailLinkProps) {
  const { currentHolder } = useSessionUser()
  const openNewTabProps = openNewTab ? { target: '_blank', rel: 'noreferrer' } : {}
  let linkTo = ''
  let defaultTestId = ''
  const defaultClassName = 'text-primary'

  switch (type) {
    case 'purchase-order':
      linkTo = '/purchase-orders/:id/summary'
      defaultTestId = 'view-po-link'
      break
    case 'requisition':
      linkTo = '/requisitions/:id/summary'
      defaultTestId = 'view-requisition-link'
      break
    case 'invoice':
      linkTo = isUtility ? '/utilities/invoices/:id/summary' : '/invoices/:id/summary'
      defaultTestId = 'view-invoice-link'
      break
    case 'credit-note':
      linkTo = '/credit-notes/:id/summary'
      defaultTestId = 'view-credit-note-link'
      break
    case 'catalog':
      linkTo = '/catalogs/:id/products'
      defaultTestId = 'view-catalog-link'
      break
    case 'catalog-product':
      linkTo = '/catalogs/:id/products/:secondaryId/product-details'
      defaultTestId = 'view-catalog-product'
      break
    case 'supplier':
      linkTo = '/my-suppliers/:id/supplier-details'
      defaultTestId = 'view-supplier-link'
      break
    case 'supplier-directory':
      linkTo = '/supplier-directory/:id/supplier-details'
      defaultTestId = 'view-supplier-directory-link'
      break
    case 'supplier-directory-products':
      linkTo = '/supplier-directory/:id/products'
      defaultTestId = 'view-supplier-directory-products-link'
      break
    case 'user':
      linkTo = '/users/:id/general'
      defaultTestId = 'view-user-link'
      break
    case 'buy-list':
      linkTo = '/buy-lists/:id/products'
      defaultTestId = 'view-buy-list-link'
      break
    case 'stocktake':
      linkTo = '/stocktakes/stock-location/:secondaryId/stocktake/:id/stock-count'
      defaultTestId = 'view-stocktake-link'
      break
    case 'receiving-document':
      linkTo = '/purchase-orders/:secondaryId/receiving-notes/:id/legacy/summary'

      if (currentHolder) {
        linkTo = '/purchase-orders/:secondaryId/receiving-notes/:id/summary'
      }
      defaultTestId = 'view-receiving-document-link'
      break
    case 'transfer':
      linkTo = '/inventory/transfers/:id/transfer-items'
      defaultTestId = 'view-transfer-link'
      break
    case 'point-of-sale-batch':
      linkTo = '/point-of-sale/batch-sales/:id/sales'
      defaultTestId = 'view-point-of-sale-batch-link'
      break
    case 'utility-organisation':
      linkTo = '/utilities/organisations/:id/general'
      defaultTestId = 'view-utility-organisation-link'
      break
    case 'utility-ap-system':
      linkTo = '/utilities/ap-systems/:id/general'
      defaultTestId = 'view-utility-ap-system-link'
      break
    case 'utility-catalog':
      linkTo = '/utilities/catalogs/:id/products'
      defaultTestId = 'view-utility-catalog-link'
      break
    case 'utility-trade-relationship':
      linkTo = '/utilities/organisations/trade-relationships/:id/general'
      defaultTestId = 'view-utility-trade-relationship-link'
      break
    case 'utility-user':
      linkTo = '/utilities/users/:id/general'
      defaultTestId = 'view-utility-user-link'
      break
    case 'utility-hierarchy':
      linkTo = '/utilities/organisations/hierarchies/:id'
      defaultTestId = 'view-utility-hierarchy-link'
      break
    case 'utility-ap-export-format':
      linkTo = '/utilities/ap-export-formats/:id/general'
      defaultTestId = 'view-utility-ap-export-format-link'
      break
    default:
      return <>{children}</>
  }

  return (
    <Link
      data-testid={testId || defaultTestId}
      to={generatePath(linkTo, { id, secondaryId })}
      className={className || defaultClassName}
      {...openNewTabProps}
    >
      {children}
    </Link>
  )
}
