import { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import { Button } from '@/modules/shared/components/button/Button'
import { ShowMoreContext } from '@/modules/shared/contexts/ShowMoreContext'

export interface TileDetailsProps extends PropsWithChildren {
  hideShowMoreButton?: boolean
  defaultOpen?: boolean
}
export const TileDetails = ({ hideShowMoreButton, defaultOpen, children }: TileDetailsProps) => {
  const { t } = useTranslation()

  const [showMoreDetails, setShowMoreDetails] = useState(false)

  return (
    <section data-testid="requisition-details" className="px-4 text-sm">
      <Accordion
        testId="accordion-tile-details"
        title={t('general.details', 'Details')}
        buttonClassName="bg-white py-4 hover:hover:brightness-100"
        titleClassName="font-bold"
        defaultOpen={defaultOpen}
      >
        <div className="mb-4 border" data-testid="accordion-tile-details-table">
          <ShowMoreContext.Provider value={{ showMoreDetails }}>{children}</ShowMoreContext.Provider>
        </div>
        {!hideShowMoreButton && (
          <Button
            data-testid="tile-details-show-more-button"
            className="mb-4 flex h-11 w-full items-center justify-center gap-x-2 rounded-md border p-4 text-center text-sm transition hover:brightness-95"
            onClick={() => setShowMoreDetails((prevState) => !prevState)}
          >
            {showMoreDetails ? t('general.showLess', 'Show Less') : t('general.showMore', 'Show More')}
          </Button>
        )}
      </Accordion>
    </section>
  )
}
