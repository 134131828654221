import { z } from 'zod'

export const CheckoutDetailsSchema = z.object({
  userEmail: z.string().email(),
  referenceNumber: z.string().optional(),
  orderInstructions: z.string().optional(),
  deliveryAddressCity: z.string().min(1),
  deliveryAddressCountry: z.string().min(1),
  deliveryAddressLine1: z.string().min(1),
  deliveryAddressLine2: z.string().optional(),
  deliveryAddressStateProvince: z.string().min(1),
  deliveryAddressPostalCode: z.string().min(1),
})

export type CheckoutDetailsFormInput = z.infer<typeof CheckoutDetailsSchema>
