import * as Sentry from '@sentry/react'
import { PropsWithChildren, useEffect } from 'react'

import useSessionUser from '@/modules/access/hooks/useSessionUser'

/**
 * Wrapper component to handle Sentry admin dialog
 */
export function SentryAdminWrapper(props: PropsWithChildren) {
  const { user } = useSessionUser()

  useEffect(() => {
    if (user?.isAdmin) {
      Sentry.showReportDialog()
    }
  }, [user?.isAdmin])

  return props.children
}
