import { Dispatch, PropsWithChildren, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/modules/shared/components/button/Button'
import { QueryParameter } from '@/modules/shared/types/QueryParameter'
import { classNames } from '@/modules/shared/utils/classNames'

interface SearchContainerProps extends PropsWithChildren {
  showAdvancedSearch: boolean
  setShowAdvancedSearch: Dispatch<SetStateAction<boolean>>
  className?: string
  openByDefault?: boolean
  ignoreFilters?: QueryParameter[]
}

export default function SearchContainer({
  children,
  showAdvancedSearch,
  setShowAdvancedSearch,
  className,
  openByDefault = false,
  ignoreFilters,
}: SearchContainerProps) {
  const { t } = useTranslation()

  // Check for advanced search query parameters
  const getQueryParams = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const queryParams: { [key in QueryParameter]?: string } = {}

    // if the query param is in this array, then the advanced search will load in the opened state
    const queryParametersArray = [
      QueryParameter.From,
      QueryParameter.To,
      QueryParameter.Enabled,
      QueryParameter.Categories,
      QueryParameter.Suppliers,
      QueryParameter.SupplierProductCode,
      QueryParameter.PurchaserProductCode,
      QueryParameter.PPlusProductCode,
      QueryParameter.MatchedInvoice,
      QueryParameter.CompletedReceivingNote,
      QueryParameter.PeriodYear,
      QueryParameter.PeriodMonth,
      QueryParameter.BestBuy,
      QueryParameter.StockLocations,
      QueryParameter.Image,
      QueryParameter.Catalogued,
      QueryParameter.Matched,
      QueryParameter.MasterProduct,
      QueryParameter.HasQuotes,
      QueryParameter.CountedItems,
      QueryParameter.RequisitionType,
      QueryParameter.Departments,
      QueryParameter.Accounts,
      QueryParameter.Destination,
      QueryParameter.Owner,
      QueryParameter.OrganisationUnit,
      QueryParameter.SelfManaged,
      QueryParameter.Preferred,
      QueryParameter.DestinationStockLocation,
      QueryParameter.OriginStockLocation,
      QueryParameter.Barcode,
      QueryParameter.Id,
      QueryParameter.TradeRelationshipId,
      QueryParameter.ApSystemId,
      QueryParameter.PurchaserId,
      QueryParameter.SupplierId,
      QueryParameter.OwnerId,
      QueryParameter.OwnerName,
      QueryParameter.Verified,
      QueryParameter.LegalEntity,
      QueryParameter.TransferTypes,
      QueryParameter.OrganisationId,
      QueryParameter.PPlusCode,
      QueryParameter.CreditNoteNumber,
      QueryParameter.InvoiceNumber,
      QueryParameter.Common,
      QueryParameter.PromptType,
      QueryParameter.CatalogOwnerId,
      QueryParameter.CatalogSupplierId,
      QueryParameter.CustomCodeEnabled,
      QueryParameter.DocumentType,
      QueryParameter.WorkflowType,
      QueryParameter.MarkedAs,
      QueryParameter.SubjectLine,
      QueryParameter.Done,
      QueryParameter.BusinessNumber,
      QueryParameter.Email,
      QueryParameter.Reference,
      QueryParameter.IsRecipe,
    ].filter((queryParameter) => !ignoreFilters?.includes(queryParameter))

    queryParametersArray.forEach((key) => {
      const value = searchParams.get(key)
      if (value !== null) {
        queryParams[key] = value
      }
    })
    return queryParams
  }

  // Check if there are any query parameters with non-undefined values
  const queryParamsPresent = Object.values(getQueryParams()).some((value) => value !== undefined)

  useEffect(() => {
    if (queryParamsPresent && !showAdvancedSearch && openByDefault) {
      setShowAdvancedSearch(true)
    }
  }, [queryParamsPresent])

  return (
    <section className="flex flex-col-reverse gap-2 lg:flex-row">
      <Button
        className={classNames('shrink-0 rounded-md px-4 py-3 text-sm', className, {
          'bg-gray-200': !queryParamsPresent,
          'bg-primary/10 text-primary hover:bg-primary/20': queryParamsPresent,
        })}
        onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
        data-testid="show-advanced-search"
      >
        {t('general.advancedSearch', 'Advanced Search')}
      </Button>
      {children}
    </section>
  )
}
