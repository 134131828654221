import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Account, Purchaser } from '@/graphql/purchasing/generated/purchasing_graphql'
import useGetOrganisationFlatAccounts from '@/modules/admin-center/hooks/useGetOrganisationFlatAccounts'
import { useCurrentPurchaser } from '@/modules/purchasing/hooks/useCurrentPurchaser'
import { ComboboxClient } from '@/modules/shared/components/combobox/client/ComboboxClient'
import ComboboxServer from '@/modules/shared/components/combobox/server/ComboboxServer'
import { Tooltip } from '@/modules/shared/components/tooltip/Tooltip'
import { useGetDepartments } from '@/modules/shared/hooks/useGetDepartments'
import { QuestionMarkIcon } from '@/modules/shared/icons/QuestionMarkIcon'
import { classNames } from '@/modules/shared/utils/classNames'

interface DeptAndAccountProps {
  defaultDepartmentValue?: Pick<Purchaser, 'id' | 'name'>
  defaultAccountValue?: Pick<Account, 'id' | 'accountName'>
  showExplanation?: boolean
  disabled?: boolean
  title?: string
  description?: string
  required?: boolean
  className?: string
  onlyDepartments?: boolean
  onlyAccounts?: boolean
  showAsRow?: boolean
  departmentTooltip?: string
  accountTooltip?: string
  purchaserId?: number
  saveAsObject?: boolean
  floating?: boolean
}

export default function DeptAndAccount({
  defaultAccountValue,
  defaultDepartmentValue,
  showExplanation = true,
  disabled,
  title,
  description,
  required = true,
  className,
  onlyDepartments,
  onlyAccounts,
  showAsRow,
  departmentTooltip,
  accountTooltip,
  purchaserId,
  saveAsObject = false,
  floating = false,
}: DeptAndAccountProps) {
  const { t } = useTranslation()
  const {
    resetField,
    getValues,
    control,
    setValue,
    formState: { errors },
  } = useFormContext()
  const [hasDefault, setHasDefault] = useState(false)
  const [resetAccount, setResetAccount] = useState(0)
  const { currentPurchaser } = useCurrentPurchaser()
  const initializedRef = useRef(false)

  // Store default values in refs to prevent them from causing re-renders
  const defaultDeptRef = useRef(defaultDepartmentValue)
  const defaultAccRef = useRef(defaultAccountValue)

  // Use memoized values for organisationId to prevent re-renders
  const organisationId = useMemo(() => {
    if (onlyAccounts && currentPurchaser?.id) {
      return Number(currentPurchaser.id)
    }
    return defaultDeptRef.current?.id ? Number(defaultDeptRef.current.id) : undefined
  }, [onlyAccounts, currentPurchaser?.id])

  const { departments, loadingStates: departmentLoadingStates } = useGetDepartments({ purchaserId })
  const {
    fetchOrganisationFlatAccounts,
    flatAccounts,
    loadingStates: accountLoadingStates,
    networkStatus: accountNetworkStatus,
    onFetchMoreFlatAccounts,
    refetchApSystemFlatAccounts,
    refetchOrganisationFlatAccounts,
    accountsPayableSystemId,
    allAccountsDisabled,
    pageInfo,
  } = useGetOrganisationFlatAccounts({
    organisationId,
    lazyQuery: true,
    isParent: false,
  })

  /**
   * Handle department selection
   * @param e - Selected department
   * @param field - Form field controller
   */
  const onSelectedDepartment = useCallback(
    (e: Pick<Purchaser, 'id' | 'name'>, field: ControllerRenderProps<FieldValues, 'departmentId' | 'department'>) => {
      field.onChange(saveAsObject ? { id: e.id, name: e.name } : e.id)
      if (!hasDefault) {
        resetField(saveAsObject ? 'account' : 'accountId', { defaultValue: null })
        fetchOrganisationFlatAccounts({
          variables: {
            organisationId: e.id,
            filters: { isParent: false },
          },
        })
        setResetAccount((prev) => prev + 1) // Increment to avoid same value
      } else {
        setHasDefault(false)
      }
    },
    [fetchOrganisationFlatAccounts, hasDefault, resetField, saveAsObject]
  )

  /**
   * Handle account search
   * @param e - Search text
   */
  const onSearchAccounts = useCallback(
    (e: string) => {
      const deptId = getValues(saveAsObject ? 'department.id' : 'departmentId')
      if (allAccountsDisabled) {
        refetchApSystemFlatAccounts({
          accountsPayableSystemId,
          filters: {
            searchText: e,
            isParent: false,
          },
        })
      } else {
        refetchOrganisationFlatAccounts({
          organisationId: deptId,
          filters: {
            searchText: e,
            isParent: false,
          },
        })
      }
    },
    [
      allAccountsDisabled,
      accountsPayableSystemId,
      getValues,
      refetchApSystemFlatAccounts,
      refetchOrganisationFlatAccounts,
      saveAsObject,
    ]
  )

  // Initialize form values once on component mount
  useEffect(() => {
    if (!initializedRef.current) {
      // Initialize department if provided
      if (defaultDeptRef.current?.id) {
        const deptId = Number(defaultDeptRef.current.id)
        setValue(saveAsObject ? 'department' : 'departmentId', saveAsObject ? defaultDeptRef.current : deptId, {
          shouldValidate: false,
          shouldDirty: false,
        })

        // Initialize account if provided
        if (defaultAccRef.current?.id) {
          setValue(
            saveAsObject ? 'account' : 'accountId',
            saveAsObject ? defaultAccRef.current : Number(defaultAccRef.current.id),
            { shouldValidate: false, shouldDirty: false }
          )
        }

        // Fetch accounts
        if (!onlyDepartments) {
          fetchOrganisationFlatAccounts({
            variables: { organisationId: deptId, filters: { isParent: false } },
          })
        }
        setHasDefault(true)
      } else if (onlyAccounts && currentPurchaser?.id) {
        // Only fetch accounts when showing accounts only
        fetchOrganisationFlatAccounts({
          variables: { organisationId: Number(currentPurchaser.id), filters: { isParent: false } },
        })
      }

      initializedRef.current = true
    }
  }, [])

  // Memoize default values to avoid re-renders
  const memoizedDeptValue = useMemo(() => defaultDeptRef.current, [])
  const memoizedAccValue = useMemo(() => defaultAccRef.current, [])

  return (
    <section
      className={classNames(className, { 'mt-8': showExplanation && !className && !showAsRow })}
      data-testid="department-and-account"
    >
      {showExplanation && (
        <>
          <h2 className="text-sm font-semibold">{title}</h2>
          <p className="text-sm text-gray-500">{description}</p>
        </>
      )}
      <div
        className={classNames('w-full', {
          'mt-3': showExplanation && !showAsRow,
          'flex gap-x-4': showAsRow,
          'space-y-3': !showAsRow,
        })}
      >
        {!onlyAccounts && (
          <div className={classNames({ 'w-1/2': showAsRow })}>
            {showAsRow && (
              <p className="mb-1.5 flex gap-x-1.5 font-bold">
                {t('general.department', 'Department')}
                <Tooltip content={departmentTooltip || ''}>
                  <QuestionMarkIcon className="size-4" />
                </Tooltip>
              </p>
            )}
            <Controller
              control={control}
              name={saveAsObject ? 'department' : 'departmentId'}
              rules={{ required }}
              render={({ field }) => (
                <ComboboxClient
                  testId="department"
                  defaultValue={memoizedDeptValue}
                  loading={departmentLoadingStates.loading}
                  placeholder={t('general.selectADepartmentDots', 'Select a Department...')}
                  keyFilter="name"
                  keyExtractor={(e) => String(e.id)}
                  items={departments?.map((dept) => ({ id: dept.id, name: dept.name })) || []}
                  onSelected={(e) => onSelectedDepartment(e, field)}
                  hasError={Boolean(saveAsObject ? errors.department : errors.departmentId)}
                  disabled={disabled}
                  floating={floating}
                />
              )}
            />
          </div>
        )}
        {!onlyDepartments && (
          <div className={classNames({ 'w-1/2': showAsRow })}>
            {showAsRow && (
              <p className="mb-1.5 flex gap-x-1.5 font-bold">
                {t('general.accountCode', 'Account Code')}
                <Tooltip content={accountTooltip || ''}>
                  <QuestionMarkIcon className="size-4" />
                </Tooltip>
              </p>
            )}
            <Controller
              control={control}
              name={saveAsObject ? 'account' : 'accountId'}
              rules={{ required }}
              render={({ field }) => (
                <ComboboxServer
                  testId="account"
                  defaultValue={memoizedAccValue}
                  loading={accountLoadingStates.setVariablesLoading}
                  disabled={
                    (disabled || (saveAsObject ? !getValues('department.id') : !getValues('departmentId'))) &&
                    !onlyAccounts
                  }
                  placeholder={t('createRequisition.deptAndAccount.placeholder.account', 'Select an Account Code...')}
                  keyExtractor={(e) => String(e.id)}
                  items={flatAccounts as unknown as Account[]}
                  onSelected={(e) => field.onChange(saveAsObject ? { id: e.id, accountName: e.accountName } : e.id)}
                  hasError={Boolean(saveAsObject ? errors.account : errors.accountId)}
                  errorMessage={t(
                    'createRequisition.deptAndAccount.required.account',
                    'You must select a Department and an Account Code.'
                  )}
                  resetSelectItem={resetAccount}
                  floating={floating}
                  networkStatus={accountNetworkStatus}
                  onDisplay={(e) => e.accountName}
                  onInputChange={onSearchAccounts}
                  onFetchMore={onFetchMoreFlatAccounts}
                  hasMore={!!pageInfo?.hasNextPage}
                />
              )}
            />
          </div>
        )}
      </div>
    </section>
  )
}
