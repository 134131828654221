import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { CheckoutDetailsFormInput } from '@/modules/public-shop/checkout/checkout-form/CheckoutDetailsSchema'
import { useGetLocalRequisition } from '@/modules/public-shop/hooks/useGetLocalRequisition'
import useGetPublicShop from '@/modules/public-shop/hooks/useGetPublicShop'
import { Tile } from '@/modules/shared/components/tile/Tile'
import { TileDetails } from '@/modules/shared/components/tile/tile-items/TileDetails'
import { TileDetailsTableItem } from '@/modules/shared/components/tile/tile-items/TileDetailsTableItem'
import { TileTitleAndTotal } from '@/modules/shared/components/tile/tile-items/TileTitleAndTotal'
import { useMoney } from '@/modules/shared/hooks/useMoney'

export function CheckoutSummary() {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const { requisition } = useGetLocalRequisition()
  const { publicShop } = useGetPublicShop()
  const { watch, getValues } = useFormContext<CheckoutDetailsFormInput>()
  watch(['referenceNumber', 'userEmail'])
  const { totalTaxValue, totalValue, totalValueExTax } = requisition

  return (
    <Tile className="mt-8">
      <TileTitleAndTotal
        title={getValues('referenceNumber')}
        subtitle={getValues('userEmail')}
        total={totalValue}
        totalTaxValue={totalTaxValue}
      />
      <TileDetails defaultOpen hideShowMoreButton>
        <TileDetailsTableItem
          title={t('general.supplier', 'Supplier')}
          value={publicShop?.name}
          tooltip={t('publicShop.checkoutDetail.supplier.tooltip', 'The Supplier that the Order is being placed with.')}
        />
        <TileDetailsTableItem
          title={t('general.totalTax', 'Total Tax')}
          value={formatMoney({ amount: totalTaxValue })}
          tooltip={t('publicShop.checkoutDetail.totalTax.tooltip', 'The Total Tax of the Order')}
        />
        <TileDetailsTableItem
          title={t('general.totalExTax', 'Total (Ex Tax)')}
          value={formatMoney({ amount: totalValueExTax })}
          tooltip={t('publicShop.checkoutDetail.totalExTax.tooltip', 'The Total of the Order excluding Tax.')}
        />
        <TileDetailsTableItem
          title={t('general.total', 'Total')}
          value={formatMoney({ amount: totalValue })}
          tooltip={t('publicShop.checkoutDetail.total.tooltip', 'The Total of the Order including Tax.')}
        />
      </TileDetails>
    </Tile>
  )
}
