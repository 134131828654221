import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PurchasePlusPublicBaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './public_graphql'

export const CreatePublicOrderDocument = gql`
  mutation CreatePublicOrder($input: CreatePublicOrderInput!) {
    createPublicOrder(input: $input) {
      order {
        uuid
      }
      errors {
        ...PurchasePlusPublicBaseError
      }
    }
  }
  ${PurchasePlusPublicBaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.CreatePublicOrderMutation, Types.CreatePublicOrderMutationVariables>
