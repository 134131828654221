import { useMutation } from '@apollo/client'

import { CreatePaymentDocument } from '@/graphql/purchase-plus-public/generated/createPayment.generated'
import { PURCHASE_PLUS_PUBLIC_GRAPHQL_API } from '@/modules/shared/constants'

export function useCreatePayment() {
  const mutation = useMutation(CreatePaymentDocument, {
    context: {
      uri: PURCHASE_PLUS_PUBLIC_GRAPHQL_API,
    },
  })

  return mutation
}
